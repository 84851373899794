import { ENGINEER_TYPE } from '../../../../constants';

export const EMS = [
  {
    value: 'emRequested',
    label: 'EM',
    title: ENGINEER_TYPE.EM.title,
    displayColor: false,
  },
  {
    value: 'tdRequested',
    title: ENGINEER_TYPE.TD.title,
    label: ENGINEER_TYPE.TD.label,
    defaultColor: false,
  },
];

export const OPERATIONS = [
  {
    value: 'operationsRequested',
    label: 'operations',
    title: 'operations',
    displayColor: false,
  },
  {
    value: 'hoeoRequested',
    label: 'HoEO',
    title: ENGINEER_TYPE.HoEO.title,
    displayColor: false,
  },
  {
    value: 'kpisRequested',
    title: ENGINEER_TYPE.KPI.title,
    label: ENGINEER_TYPE.KPI.label,
    defaultColor: false,
  },
];

export const REFS = [
  {
    value: 'referentsRequested',
    label: 'referents',
    title: 'referents',
    displayColor: false,
  },
  {
    value: 'dataReferentRequested',
    title: ENGINEER_TYPE.DATARRef.title,
    label: ENGINEER_TYPE.DATARRef.label,
  },
  {
    value: 'cybersecurityReferentRequested',
    title: ENGINEER_TYPE.CYBSRRef.title,
    label: ENGINEER_TYPE.CYBSRRef.label,
  },
  {
    value: 'ecodesignReferentRequested',
    title: ENGINEER_TYPE.ECODRRef.title,
    label: ENGINEER_TYPE.ECODRRef.label,
  },
  {
    value: 'uxDesignReferentRequested',
    title: ENGINEER_TYPE.UXDRRef.title,
    label: ENGINEER_TYPE.UXDRRef.label,
  },
  {
    value: 'dataProtectionReferentRequested',
    title: ENGINEER_TYPE.DPRef.title,
    label: ENGINEER_TYPE.DPRef.label,
  },
  {
    value: 'makeBuyFootprintReferentRequested',
    title: ENGINEER_TYPE.MOBFRef.title,
    label: ENGINEER_TYPE.MOBFRef.label,
  },
  {
    value: 'engineeringQualityReferentRequested',
    title: ENGINEER_TYPE.EQ.title,
    label: ENGINEER_TYPE.EQ.label,
  },
  {
    value: 'otherReferentRequested',
    title: ENGINEER_TYPE.OTHERRef.title,
    label: ENGINEER_TYPE.OTHERRef.label,
  },
  {
    value: 'e2mRequested',
    title: ENGINEER_TYPE.E2M.title,
    label: ENGINEER_TYPE.E2M.label,
  },
];

export const DELIVERIES = [
  {
    value: 'deliveriesRequested',
    delivery: {
      name: 'Delivery',
      title: 'Delivery',
      value: 'deliveryNameRequested',
    },
    leader: {
      name: 'HoED',
      title: ENGINEER_TYPE.HoED.title,
      label: ENGINEER_TYPE.HoED.label,
      value: 'hoedRequested',
    },
    edmManager: {
      name: 'EDM',
      title: ENGINEER_TYPE.EDM.title,
      label: ENGINEER_TYPE.EDM.label,
      value: 'edmManagerRequested',
    },
    wpmManager: {
      name: 'WPM',
      title: ENGINEER_TYPE.WPM.title,
      label: ENGINEER_TYPE.WPM.label,
      value: 'wpmManagerRequested',
    },
    solutions: [
      {
        value: 'solutionsRequested',
        da: {
          name: 'DA',
          title: ENGINEER_TYPE.DA.title,
          label: ENGINEER_TYPE.DA.label,
          value: 'daRequested',
        },
        pda: {
          name: 'PDA',
          title: ENGINEER_TYPE.PDA.title,
          label: ENGINEER_TYPE.PDA.label,
          value: 'pdaRequested',
        },
        leader: {
          name: 'EDM',
          title: ENGINEER_TYPE.EDM.title,
          label: ENGINEER_TYPE.EDM.label,
          value: 'edmRequested',
        },
        wpms: [
          {
            value: 'wpRequested',
            leader: {
              name: 'WPM',
              title: ENGINEER_TYPE.WPM.title,
              label: ENGINEER_TYPE.WPM.label,
              value: 'wpmRequested',
            },
          },
        ],
      },
    ],
  },
];

export const DISCIPLINES = [
  {
    value: 'disciplinesRequested',
    discipline: {
      name: 'Discipline',
      value: 'disciplineNameRequested',
      title: 'Discipline',
    },
    leader: {
      name: 'HoD',
      title: ENGINEER_TYPE.HoD.title,
      label: ENGINEER_TYPE.HoD.label,
      value: 'hodRequested',
    },
    engineers: {
      name: 'Eng',
      title: 'Engineers',
      value: 'engineersRequested',
    },
  },
];

export const CER = [
  {
    value: 'cerRequested',
    title: ENGINEER_TYPE.CER.title,
    label: ENGINEER_TYPE.CER.label,
  },
];

export const DELIVERY_CHILDREN = [
  'deliveryNameRequested',
  'hoedRequested',
  'solutionsRequested',
  'edmRequested',
  'pdaRequested',
  'daRequested',
  'wpRequested',
  'wpmRequested',
  'wpmManagerRequested',
  'edmManagerRequested',
];

export const SOLUTION_CHILDREN = [
  'edmRequested',
  'pdaRequested',
  'daRequested',
  'wpRequested',
  'wpmRequested',
];

export const DISCIPLINE_CHILDREN = [
  'hodRequested',
  'disciplineNameRequested',
  'engineersRequested',
];

export const OPERATION_CHILDREN = ['hoeoRequested', 'kpisRequested'];

export const REFERENT_CHILDREN = [
  'dataReferentRequested',
  'cybersecurityReferentRequested',
  'ecodesignReferentRequested',
  'uxDesignReferentRequested',
  'dataProtectionReferentRequested',
  'makeBuyFootprintReferentRequested',
  'engineeringQualityReferentRequested',
  'otherReferentRequested',
  'e2mRequested',
];
