import React, { useContext, useEffect, useState } from 'react';
import { Collapse, Grid, IconButton, Box } from '@mui/material';
import { TdsIcon } from '@tds/react';
import { EmwCheckbox } from '../../../../../../../../../lib/common';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';
import ExportOrganizationListItem from './ExportOrganizationListItem';
import { StyledExportOrganizationHead } from './styled';
import { ExportOrganizationContext } from '../../context/ExportOrganizationContext';
import {
  DELIVERY_CHILDREN as deliveryChildren,
  SOLUTION_CHILDREN as solutionChildren,
  DISCIPLINE_CHILDREN as disciplineChildren,
  OPERATION_CHILDREN as operationChildren,
  REFERENT_CHILDREN as referentChildren,
} from '../../constants';
import deepdash from 'deepdash';

deepdash(_);

export default function ExportOrganizationList({ name, value, list, icon }) {
  const [isOpen, setIsOpen] = useState(false);
  const [openAll, setOpenAll] = useState(false);
  const context = useContext(ExportOrganizationContext);

  const handleClick = (e, value) => {
    const { checked } = e.target;

    const childrenMapping = {
      deliveriesRequested: deliveryChildren,
      solutionsRequested: solutionChildren,
      disciplinesRequested: disciplineChildren,
      operationsRequested: operationChildren,
      referentsRequested: referentChildren,
    };

    if (childrenMapping[value]) {
      childrenMapping[value].forEach(child => {
        context.setEngRequested(child, checked);
      });
    }

    if (value === 'wpRequested') {
      context.setEngRequested('wpmRequested', checked);
    }

    context.setEngRequested(value, checked);
  };

  useEffect(() => {
    return () => {
      setOpenAll(false);
      setIsOpen(false);
    };
  }, [list]);

  const isAllRequested = children => children.every(child => context.state[child]);
  const allOperationsRequested = isAllRequested(operationChildren);
  const allReferentsRequested = isAllRequested(referentChildren);

  useEffect(() => {
    if (allOperationsRequested) {
      context.setEngRequested('operationsRequested', true);
    }
    if (allReferentsRequested) {
      context.setEngRequested('referentsRequested', true);
    }
  }, [allOperationsRequested, allReferentsRequested]);

  const handleCloseOrganizationList = () => {
    if (openAll && isOpen) {
      setOpenAll(!openAll);
    }
    setIsOpen(!isOpen);
  };

  const renderList = () => {
    return (
      list &&
      list.map((item, index) => {
        return (
          <ExportOrganizationListItem item={item} expandAll={openAll} id={`${name}-${index}`} />
        );
      })
    );
  };

  return (
    <Grid container id="export-org-list" xs={12}>
      <StyledExportOrganizationHead container xs={9} id="export-org-list-head">
        {icon && (
          <Grid item mx={1}>
            <TdsIcon icon={icon} variant="outlined" classes="text-bluegrey-600"></TdsIcon>
          </Grid>
        )}
        <EmwTypography classes="text-bluegrey-600" fontSize="9" fontWeight="bold">
          {name}
        </EmwTypography>
        {list && (
          <Grid item ml={'auto'}>
            <Grid container>
              <IconButton
                title={`${isOpen ? 'Collapse section' : 'Expand section'}`}
                disableRipple
                size="small"
                id={`btnToggle${name}`}
                onClick={() => handleCloseOrganizationList()}
                sx={{ marginLeft: 'auto' }}
              >
                {isOpen ? (
                  <TdsIcon icon="keyboard_arrow_up" size="small" style={{ color: '#4A4D65' }} />
                ) : (
                  <TdsIcon icon="keyboard_arrow_down" size="small" style={{ color: '#4A4D65' }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        )}
      </StyledExportOrganizationHead>
      <Grid item className="mt-xs ml-m">
        <EmwCheckbox
          size="small"
          checked={context.state[value]}
          onCheckBoxChanged={event => handleClick(event, value)}
        />
      </Grid>
      <Collapse in={isOpen || openAll} sx={{ width: '100%' }}>
        <Box className="mt-xxs"> {renderList()}</Box>
      </Collapse>
    </Grid>
  );
}
