import React, { createContext, useState } from 'react';

export const ExportOrganizationContext = createContext({});

export default function ExportOrganizationContextProvider({ children }) {
  const initialState = {
    timestamp: null,
    hcCalculatedRequested: false,
    hcJobFamilyRequested: false,
    hcDeclaredRequested: false,
    countriesRequested: false,
    organisationTypeRequested: false,
    engDomainRequested: false,
    guestPowerGuestRequested: false,
    underlyingHierarchyRequested: false,
    emRequested: true,
    tdRequested: true,
    edmManagerRequested: true,
    wpmManagerRequested: true,
    hoeoRequested: true,
    dataReferentRequested: true,
    cybersecurityReferentRequested: true,
    ecodesignReferentRequested: true,
    uxDesignReferentRequested: true,
    dataProtectionReferentRequested: true,
    makeBuyFootprintReferentRequested: true,
    engineeringQualityReferentRequested: true,
    otherReferentRequested: true,
    e2mRequested: true,
    kpisRequested: true,
    deliveriesRequested: true,
    deliveryNameRequested: true,
    solutionsRequested: true,
    hoedRequested: true,
    edmRequested: true,
    daRequested: true,
    pdaRequested: true,
    wpRequested: true,
    wpmRequested: true,
    disciplinesRequested: true,
    disciplineNameRequested: true,
    hodRequested: true,
    engineersRequested: true,
    referentsRequested: true,
    operationsRequested: true,
    cerRequested: true,
    requestedGroup: false,
    requestedGbus: [],
    requestedBls: [],
    requestedCcs: [],
    fileName: '',
    path: '',
    exportType: '',
  };

  const [state, setState] = useState(initialState);

  const setEngRequested = (value, checked) => {
    setState(prevState => ({
      ...prevState,
      [value]: checked,
    }));
  };

  const setOperationsRequested = (value, checked) => {
    setState(prevState => ({
      ...prevState,
      [value]: checked,
    }));
  };

  const setRefsRequested = (value, checked) => {
    setState(prevState => ({
      ...prevState,
      [value]: checked,
    }));
  };

  const setRequestedEntities = (value, selected) => {
    setState(prevState => ({
      ...prevState,
      [value]: selected,
    }));
  };

  const setFilename = value => {
    setState(prevState => ({
      ...prevState,
      fileName: value,
    }));
  };

  const setPath = value => {
    setState(prevState => ({
      ...prevState,
      path: value,
    }));
  };

  const setExport = value => {
    setState(prevState => ({
      ...prevState,
      exportType: value,
    }));
  };

  const resetOnClose = () => {
    setState(initialState);
  };

  const setTimestamp = value => {
    setState(prevState => ({
      ...prevState,
      timestamp: value,
    }));
  };

  const contextValue = {
    state,
    setState,
    setEngRequested,
    setOperationsRequested,
    setRefsRequested,
    setRequestedEntities,
    setFilename,
    resetOnClose,
    setPath,
    setTimestamp,
    setExport,
  };

  return (
    <ExportOrganizationContext.Provider value={contextValue}>
      {children}
    </ExportOrganizationContext.Provider>
  );
}
