import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { Grid, Divider, Box, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import '../styled';
import deepdash from 'deepdash';
import ExportDropdown from './ExportDropdown';
import { TdsTypography, TdsTextInput, TdsCheckbox } from '@tds/react';
import {
  gbuListSelector,
  orgDetailsSelector,
  schemaSelector,
} from '../../../../../redux/selectors';
import { ExportOrganizationContext } from '../context/ExportOrganizationContext';
import TourElement from '../../../../../../../components/Tour/TourElement/TourElement';

const selectAll = {
  id: 0,
  code: 'Select All',
  name: 'Select All',
  childs: [],
};

deepdash(_);

export default function ExportOrganizationSectionRight({ data }) {
  const DATE_FORMAT = 'DD-MM-yyyy';
  const date = moment(Date.now()).format(DATE_FORMAT);
  const gbuList = useSelector(gbuListSelector);
  const schema = useSelector(schemaSelector);
  const orgDetails = useSelector(orgDetailsSelector);
  const context = useContext(ExportOrganizationContext);

  const [gbuOptions, setGbuOptions] = useState([]);
  const [blOptions, setBlOptions] = useState([]);
  const [ccOptions, setCcOptions] = useState([]);
  const [filename, setFilename] = useState('');

  const [selectedGbu, setSelectedGbu] = useState();
  const [selectedBl, setSelectedBl] = useState();
  const [selectedCc, setSelectedCc] = useState();
  const [ccDisabled, setCcDisabled] = useState(false);
  const [blDisabled, setBlDisabled] = useState(false);
  const [gbuDisabled, setGbuDisabled] = useState(false);
  const [groupCheckVisible, setGroupCheckVisible] = useState(false);

  const cardDetails = orgDetails.list.data.cardDetails;
  const { type } = cardDetails;

  const gbuCode = schema.path.selectedGBU.code;
  const gbuId = schema.path.selectedGBU.id;
  const blCode = schema.path.selectedBL.code;
  const blId = schema.path.selectedBL.id;
  const ccCode = orgDetails.list.data.section1.name.value;
  const ccId = cardDetails.id;

  useEffect(() => {
    if (type === 'CC') {
      setFilename(`EMW Export ${ccCode} ${date}`);
      context.setFilename(`EMW Export ${ccCode} ${date}`);
      const gbuOption = gbuList.find(gbu => gbu.code === gbuCode);
      const blItems = gbuOption.childs.map(bl => ({
        id: bl.id,
        code: bl.code,
        name: bl.name,
        childs: bl.childs,
      }));
      const blOption = blItems.find(item => item.code === blCode);

      setSelectedGbu({
        code: gbuCode,
        id: gbuId,
      });
      setSelectedBl([
        {
          code: blCode,
          id: blId,
          childs: blOption.childs,
        },
      ]);
      setSelectedCc([
        {
          code: ccCode,
          id: ccId,
        },
      ]);

      setGbuDisabled(true);
      setBlDisabled(true);
      setCcDisabled(true);

      const gbuSelected = { id: gbuId, requested: false };
      const blSelected = { id: blId, requested: false };
      const ccSelected = { id: ccId, requested: true };
      context.setRequestedEntities('requestedGbus', [gbuSelected]);
      context.setRequestedEntities('requestedBls', [blSelected]);
      context.setRequestedEntities('requestedCcs', [ccSelected]);
    }

    if (type === 'BL') {
      setFilename(`EMW Export ${blCode} ${date}`);
      context.setFilename(`EMW Export ${blCode} ${date}`);
      const gbuOption = gbuList.find(gbu => gbu.code === gbuCode);
      const blItems = gbuOption.childs.map(bl => ({
        id: bl.id,
        code: bl.code,
        name: bl.name,
        childs: bl.childs,
      }));
      const blOption = blItems.find(item => item.code === blCode);
      const ccItems = blOption.childs.map(cc => ({ id: cc.id, code: cc.name }));
      ccItems.unshift(selectAll);
      setSelectedGbu({
        code: gbuCode,
        id: gbuId,
      });
      setSelectedBl([
        {
          code: blCode,
          id: blId,
          childs: blOption.childs,
        },
      ]);
      setCcOptions(ccItems);

      setGbuDisabled(true);
      setBlDisabled(true);

      const gbuSelected = { id: gbuId, requested: false };
      const blSelected = { id: blId, requested: true };
      context.setRequestedEntities('requestedGbus', [gbuSelected]);
      context.setRequestedEntities('requestedBls', [blSelected]);
    }

    if (type === 'GBU') {
      setFilename(`EMW Export ${gbuCode} ${date}`);
      context.setFilename(`EMW Export ${gbuCode} ${date}`);
      setSelectedGbu({
        code: gbuCode,
        id: gbuId,
      });
      const gbuOption = gbuList.find(gbu => gbu.code === gbuCode);
      const blItems = gbuOption.childs.map(bl => ({
        id: bl.id,
        code: bl.code,
        name: bl.name,
        childs: bl.childs,
        checked: true,
      }));
      const ccItems = blItems.flatMap(bl =>
        bl.childs.map(child => ({ id: child.id, code: child.name, checked: true }))
      );
      blItems.unshift(selectAll);
      ccItems.unshift(selectAll);
      setBlOptions(blItems);
      setCcOptions(ccItems);

      setGbuDisabled(true);

      const gbuSelected = { id: gbuId, requested: true };
      context.setRequestedEntities('requestedGbus', [gbuSelected]);
    }

    if (type === 'GROUP') {
      setGroupCheckVisible(true);
      setFilename(`EMW Export Thales Group ${date}`);
      context.setFilename(`EMW Export Thales Group ${date}`);
      context.setEngRequested('requestedGroup', true);
      const gbus = gbuList.map(gbu => ({ id: gbu.id, code: gbu.code, childs: gbu.childs }));
      gbus.unshift(selectAll);
      setGbuOptions(gbus);

      const bls = [selectAll];
      const ccs = [selectAll];

      _.eachDeep(
        gbuList,
        (child, i, parent, ctx) => {
          if (ctx.depth === 2) {
            bls.push({ id: child.id, code: child.code, childs: child.childs });
          }
          if (ctx.depth === 3) {
            ccs.push({ id: child.id, code: child.name });
          }
        },
        { childrenPath: 'childs' }
      );

      setBlOptions(bls);
      setCcOptions(ccs);
    }
  }, []);

  useEffect(() => {
    if (type === 'GROUP' && selectedGbu) {
      const blOptions = selectedGbu.length > 0 ? [selectAll] : [];
      const ccOptions = selectedGbu.length > 0 ? [selectAll] : [];
      _.eachDeep(
        selectedGbu,
        (child, i, parent, ctx) => {
          if (ctx.depth === 2) {
            blOptions.push({ id: child.id, code: child.code, childs: child.childs });
          }
          if (ctx.depth === 3) {
            ccOptions.push({ id: child.id, code: child.name });
          }
        },
        { childrenPath: 'childs' }
      );

      // TODO: uncomment if we need to display only children, not all
      // setBlOptions(blOptions);
      // setCcOptions(ccOptions);
      const requestedGbus = selectedGbu
        .filter(item => item.id !== 0)
        .map(gbu => {
          return {
            id: gbu.id,
            requested: true,
          };
        });
      context.setRequestedEntities('requestedGbus', requestedGbus);
    }
  }, [selectedGbu]);

  useEffect(() => {
    if (!selectedBl) {
      return;
    }

    if (selectedBl.length === 0) {
      context.setRequestedEntities('requestedCcs', []);
    }

    const requestedBls = selectedBl
      .filter(item => item.id !== 0)
      .map(bls => {
        return {
          id: bls.id,
          requested: selectedCc ? false : true,
        };
      });
    context.setRequestedEntities('requestedBls', requestedBls);

    const ccItems = selectedBl.flatMap(bl =>
      bl.childs.map(child => ({ id: child.id, code: child.name }))
    );
    if (ccItems.length > 0) {
      ccItems.unshift(selectAll);
    }
    // TODO: uncomment if we need to display only children, not all
    // setCcOptions(ccItems);
  }, [selectedBl]);

  useEffect(() => {
    if (!selectedCc) {
      return;
    }
    const requestedCcs = selectedCc
      .filter(item => item.id !== 0)
      .map(ccs => {
        return {
          id: ccs.id,
          requested: true,
        };
      });
    context.setRequestedEntities('requestedCcs', requestedCcs);
  }, [selectedCc]);

  const getSelectedOptions = (options, values, reason, details) => {
    let _values;
    const hasSelectAll = details && details.option && details.option.id === 0;

    if (hasSelectAll) {
      if (reason !== 'removeOption') {
        return options;
      } else {
        return [];
      }
    }

    if (options.length !== values.length) {
      _values = values.filter(value => value.id !== 0);
    }

    if (_values && _values.length > 0) {
      return _values.map(v => ({ id: v.id, code: v.code, childs: v.childs }));
    } else {
      return [];
    }
  };

  const handleSelectGBU = (event, values, reason, details) => {
    const gbus = getSelectedOptions(gbuOptions, values, reason, details);
    setSelectedGbu(gbus);
  };

  const handleSelectBL = (event, values, reason, details) => {
    const bls = getSelectedOptions(blOptions, values, reason, details);
    setSelectedBl(bls);
  };

  const handleSelectCC = (event, values, reason, details) => {
    const ccs = getSelectedOptions(ccOptions, values, reason, details);
    setSelectedCc(ccs);
  };

  const handleChangeFilename = event => {
    //TODO: use some kind of timeout
    const updatedFilename = event.target.value;
    setFilename(updatedFilename);
    context.setFilename(updatedFilename);
  };

  const handleSelectGroup = (event, value) => {
    const { checked } = event.target;
    context.setEngRequested('requestedGroup', checked);
  };

  return (
    <Grid item className="mt-s ml-s mr-s">
      {groupCheckVisible && (
        <TourElement id="tour-thales-group-export">
          <Grid item sx={{ width: '350px' }}>
            <TdsTextInput
              placeholder="Thales Group"
              disabled="true"
              classes="mt-xs mb-xs pb-s"
              size="small"
              value={'Thales Group'}
            />
          </Grid>
        </TourElement>
      )}

      <TourElement id="tour-dropdowns-export">
        <TdsTypography component="body-1" fontSize={9}>
          Choose one or more of the GBU’s below
        </TdsTypography>
        <ExportDropdown
          options={gbuOptions}
          value={selectedGbu}
          handleChange={handleSelectGBU}
          disabled={gbuDisabled}
          type="GBU"
          selectedOptions={selectedGbu}
        />
        <TdsTypography component="body-1" fontSize={9}>
          Choose one or more of the BL’s below
        </TdsTypography>
        <ExportDropdown
          options={blOptions}
          value={selectedBl}
          handleChange={handleSelectBL}
          disabled={blDisabled}
          type="BL"
          selectedOptions={selectedBl}
        />
        <TdsTypography component="body-1" fontSize={9}>
          Choose one of the CC’s below
        </TdsTypography>
        <ExportDropdown
          options={ccOptions}
          value={selectedCc}
          // indeterminate={indeterminate}
          handleChange={handleSelectCC}
          disabled={ccDisabled}
          type="CC"
          selectedOptions={selectedCc}
        />
      </TourElement>
      <Divider flexItem sx={{ padding: '10px' }} />

      <Grid item className="mt-s" sx={{ width: '350px' }}>
        <TourElement id="tour-file-name">
          <TdsTypography component="body-1" fontSize={9}>
            Filename:
          </TdsTypography>
          <TdsTextInput
            placeholder="EMW-Export.xls"
            classes="mt-xs mb-xs pb-s export-filename"
            size="small"
            onValueChanged={handleChangeFilename}
            value={filename}
          />
        </TourElement>
      </Grid>
    </Grid>
  );
}
