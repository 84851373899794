import React from 'react';
import { Box, Grid } from '@mui/material';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';
import { EmwCheckbox } from '../../../../../../../../../lib/common';

export default function ExportEngineersList({ label, title, value, handleClick, checked }) {
  return (
    <Grid id={`engineer-${label}`} container alignItems="center" sx={{ maxHeight: '40px' }}>
      <Grid
        item
        xs={9}
        mr={1}
        px={1}
        alignItems="center"
        sx={{
          backgroundColor: '#F7F7F9',
          borderRadius: 2,
          minHeight: '30px',
          marginBottom: '5px',
          marginTop: '5px',
        }}
      >
        <Box title={title}>
          <EmwTypography fontSize="9" fontWeight="bold" classes="text-bluegrey-800 mt-xs">
            {label || title}
          </EmwTypography>
        </Box>
      </Grid>
      <Grid item className="ml-xs">
        <EmwCheckbox
          size="small"
          checked={checked}
          onCheckBoxChanged={e => handleClick(e, value)}
        ></EmwCheckbox>
      </Grid>
    </Grid>
  );
}
