import React, { useContext, useState } from 'react';
import { Box, Grid } from '@mui/material';
import ExportEngineersList from './Section1/ExportEngineersList';
import ExportOrganizationList from './Section2/ExportOrganizationList';
import { ExportOrganizationContext } from '../context/ExportOrganizationContext';
import EmwTypography from '../../../../../../../components/EmwTypography/EmwTypography';
import { EmwCheckbox } from '../../../../../../../../lib/common';
import TourElement from '../../../../../../../components/Tour/TourElement/TourElement';
import {
  EMS as ems,
  OPERATIONS as operations,
  REFS as refs,
  DELIVERIES as deliveries,
  DISCIPLINES as disciplines,
  CER as cer,
} from '../constants';

const allItems = [...ems, ...operations, ...refs, ...deliveries, ...disciplines, ...cer];

const extractValues = items => {
  const values = [];

  const extract = obj => {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        extract(obj[key]); // Recurse for nested objects
      } else if (key === 'value') {
        values.push(obj[key]); // Collect 'value' properties
      }
    }
  };

  items.forEach(item => extract(item));
  return values;
};
const initialSelectAllChecks = extractValues(allItems);

export default function ExportOrganizationSectionLeft({ data }) {
  const context = useContext(ExportOrganizationContext);
  const [selectAll, setSelectAll] = useState(true);
  const engCategories = data.section1.engineersCategories.map(item => ({
    type: item.type,
    visible: item.viewMode.visible,
  }));

  const isCer = engCategories.find(item => item.type === 'CER')?.visible || false;

  const handleEmClick = (event, value) => {
    const { checked } = event.target;
    context.setEngRequested(value, checked);
  };

  const handleSelectAll = event => {
    const { checked } = event.target;
    setSelectAll(checked);
    initialSelectAllChecks.forEach(item => {
      context.setEngRequested(item, checked);
    });
  };

  const renderSelectAll = () => {
    return (
      <Grid container id="select-all-checkbox">
        <Grid item xs={9} className=" mr-xs px-xs" />
        <Grid item className="ml-xs">
          <EmwCheckbox
            size="small"
            checked={selectAll}
            onCheckBoxChanged={handleSelectAll}
          ></EmwCheckbox>
        </Grid>
        <Grid item>
          <EmwTypography fontSize="8" fontWeight="bold" classes="text-bluegrey-800">
            Select All
          </EmwTypography>
        </Grid>
      </Grid>
    );
  };

  const renderEms = () => {
    return ems.map(item => {
      return (
        <ExportEngineersList
          label={item.label}
          value={item.value}
          title={item.title}
          handleClick={handleEmClick}
          checked={context.state[item.value]}
        />
      );
    });
  };

  const renderExportOrganizationList = (name, value, list, icon) => {
    return <ExportOrganizationList name={name} value={value} list={list} icon={icon} />;
  };

  const renderRefs = () => {
    return renderExportOrganizationList('REFERENTS', refs[0].value, refs, 'construction');
  };

  const renderCer = () => {
    return renderExportOrganizationList(cer[0].title, cer[0].value, null, 'public');
  };

  const renderOperations = () => {
    return renderExportOrganizationList(
      'OPERATIONS',
      operations[0].value,
      operations,
      'construction'
    );
  };

  const renderDeliveries = () => {
    return renderExportOrganizationList(
      'DELIVERY DISCIPLINE',
      deliveries[0].value,
      deliveries,
      'construction'
    );
  };

  const renderDisciplines = () => {
    return renderExportOrganizationList(
      'DISCIPLINES',
      disciplines[0].value,
      disciplines,
      'factory'
    );
  };

  return (
    <>
      <TourElement id="tour-export-list">
        <Grid item id="export-dialog-select-all" className="pl-xxs pb-s">
          {renderSelectAll()}
        </Grid>
        <Grid item id="export-dialog-eng-list" className="pl-xxs pb-xxs">
          {renderEms()}
        </Grid>
        <Grid item id="export-operations-list" className="pl-xxs">
          {renderOperations()}
        </Grid>
        {isCer && (
          <Grid item id="export-cer" className="pl-xxs pt-xs">
            {renderCer()}
          </Grid>
        )}

        <TourElement id="tour-colors">
          <Grid item id="export-referents-list" className="pl-xxs pt-xxs pb-xxs"></Grid>
          <Grid item className="pl-xxs">
            {renderRefs()}
          </Grid>
        </TourElement>
      </TourElement>
      <Box borderBottom="2px solid #041295" className="mt-s mb-xs mr-l" />
      <TourElement id="tour-bottom-export">
        <Grid container>
          <Grid item xs={12} className="mt-xs pl-xxs mb-xs" id="export-dialog-deliveries-lists">
            {renderDeliveries()}
          </Grid>
          <Grid item xs={12} className="pl-xxs mb-xs" id="export-dialog-disciplines-lists">
            {renderDisciplines()}
          </Grid>
        </Grid>
      </TourElement>
    </>
  );
}
