import React, { useEffect, useState } from 'react';
import { TextField, Chip, Grid } from '@mui/material';
import { StyledAutocomplete } from './styled';
import { EmwCheckbox } from '../../../../../../../../lib/common';
import EmwTooltip from '../../../../../../../components/EmwToggleMenu/EmwTooltip';
import EmwTag from '../../../../../../../components/EmwTag/EmwTag';

export default function ExportDropdown({
  options,
  handleChange,
  disabled,
  value,
  type,
  selectedOptions,
}) {
  const [defaultValue, setDefaultValue] = useState([]);

  useEffect(() => {
    if (!value) {
      return;
    }
    if (Array.isArray(value)) {
      setDefaultValue(value);
    } else {
      setDefaultValue([value]);
    }
  }, [value]);

  const handleSelectAll = () => {
    if (selectedOptions && selectedOptions.length > 1) {
      const filteredData = selectedOptions.filter(item => item.code !== 'Select All');
      return (
        <Grid container className="max-h-20xl overflow-auto">
          {filteredData.map((option, index) => (
            <EmwTag
              variant="STANDARD"
              classes="mr-xxs mb-xxs mt-xxs"
              size="small"
              dismissible={false}
              text={option.code}
              color="#636BBE"
            />
          ))}
        </Grid>
      );
    }
  };

  return (
    <>
      <StyledAutocomplete
        multiple
        disabled={disabled}
        disableCloseOnSelect
        id={`export-dropdown-list-${type}`}
        options={options ? options : []}
        size="small"
        onChange={handleChange}
        getOptionLabel={option => option.code}
        // getOptionSelected={(option, value) => option.id === defaultValue.id}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={defaultValue}
        defaultValue={defaultValue}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingBottom: '8px',
          paddingTop: '8px',
        }}
        renderTags={(tagValue, getTagProps) => {
          if (tagValue.length > 1) {
            return null;
          } else {
            return <Chip label={tagValue[0].code} {...getTagProps({})} />;
          }
        }}
        renderOption={(props, option, { selected }) => {
          const _props = {
            ...props,
            key: props.id,
          };

          return (
            <li {..._props}>
              <EmwCheckbox checked={selected} onChange={handleChange} />
              {option.code}
            </li>
          );
        }}
        renderInput={params => {
          const getPlaceholderText = () => {
            if (value && value.length === options.length) {
              return `All ${type}'s selected`;
            }
            if ((value && value.length > 1) || defaultValue.length > 1) {
              return `Multiple ${type}'s selected`;
            }
            if (!value || value.length === 0) {
              return 'Select your answer';
            }
            return '';
          };

          return (
            <EmwTooltip title={handleSelectAll()}>
              <TextField {...params} placeholder={getPlaceholderText()} size="small" />
            </EmwTooltip>
          );
        }}
      />
    </>
  );
}
